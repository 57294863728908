.render-option-pricelist-field > li {
  border-bottom: 0.5px solid #9b9b9b;
  border-top: 0.5px solid #9b9b9b;
  margin-bottom: 8px;
  padding-top: 15px; }

.render-option-pricelist-field .incentive-price-form {
  padding: 5px; }
  .render-option-pricelist-field .incentive-price-form .rate-group {
    background-color: #ececec; }
  .render-option-pricelist-field .incentive-price-form .incentive-price-field-set {
    display: flex;
    margin-bottom: 10px;
    align-items: center; }
