.loader {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0; }

.loader-box {
  position: relative;
  width: 100%;
  height: 100%; }

.loader-spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); }
