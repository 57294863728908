img,
legend {
  border: 0; }

legend,
td,
th {
  padding: 0; }

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  margin: 0; }

aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
icle,
main,
menu,
nav,
section,
summary {
  display: block; }

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden],
template {
  display: none; }

a {
  background-color: transparent; }

a:active,
a:hover {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b,
optgroup,
strong {
  font-weight: 700; }

dfn {
  font-style: italic; }

h1 {
  font-size: 2em;
  margin: 0.67em 0; }

mark {
  background: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 1em 40px; }

hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0; }

pre,
textarea {
  overflow: auto; }

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0; }

button {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled],
html input[disabled] {
  cursor: default; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input {
  line-height: normal; }

input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
  padding: 0; }

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  height: auto; }

input[type='search'] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none; }

fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

input,
select,
button {
  margin: 0;
  padding: 0;
  vertical-align: middle;
  box-sizing: border-box;
  border: none;
  outline: none !important; }

img {
  border: none;
  vertical-align: top; }

button {
  display: inline-block;
  background: transparent;
  border: 0;
  cursor: pointer;
  outline: none;
  line-height: 1;
  -ms-user-select: none;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none; }

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

label {
  vertical-align: middle; }

i,
em,
address {
  font-style: normal; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none; }
  a:hover {
    text-decoration: none; }

/* Form Reset */
input,
label,
select,
button,
textarea {
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
  background: none;
  font-size: 1em; }

input,
button,
textarea {
  box-sizing: border-box;
  -webkit-appearance: none; }

input[type='password'] {
  font-family: initial; }

button,
input[type='reset'],
input[type='button'],
input[type='submit'],
input[type='file'] {
  overflow: visible;
  cursor: pointer; }

input[type='search']::-webkit-search-cancel-button {
  -webkit-appearance: none; }

.container {
  height: calc(100% - 40px);
  overflow-x: hidden;
  overflow-y: auto;
  padding-left: 150px;
  padding-right: 0px; }

.site-contents {
  min-height: 100%; }

.site-tab-menu {
  background: #004ea2;
  padding: 7px 20px 0;
  height: 39px;
  position: fixed;
  top: 50px;
  z-index: 2;
  width: 100%; }

.page-content {
  padding: 20px;
  padding-top: 50px; }

fieldset {
  border: 0;
  padding: 0.35em 0.9em 0.75em; }

.form-control-button {
  padding: 0 0.9em;
  text-align: right; }

.input-radio input[type='radio'] {
  -webkit-appearance: radio !important; }

.input-radio div label span span {
  display: inline-block;
  padding-left: 0.5rem;
  padding-right: 1rem; }

.errors {
  display: none; }

.tabs-active {
  color: #000;
  font-weight: 900; }
