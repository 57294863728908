/* bootstrap override */
/* breakpoints */
.nav-bar {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 50px;
  left: 0;
  height: 100%;
  width: 150px;
  margin: 50px, 10px;
  padding-top: 5px;
  background-color: #f6f9fa;
  border-right: 1px solid #ababab;
  z-index: 10;
  transition: top 0.2s ease-in-out;
  margin-bottom: 50px; }
  .nav-bar .nav-link {
    margin-top: 5px; }
    .nav-bar .nav-link a {
      text-decoration: none;
      color: #979797;
      font-size: 14px; }
    .nav-bar .nav-link .active,
    .nav-bar .nav-link .selected {
      color: #212529;
      font-weight: bold; }
      .nav-bar .nav-link .active .nav-tab,
      .nav-bar .nav-link .selected .nav-tab {
        display: flex; }
        .nav-bar .nav-link .active .nav-tab .bottom-dot,
        .nav-bar .nav-link .selected .nav-tab .bottom-dot {
          width: 6px;
          height: 6px;
          margin: auto 5px;
          border-radius: 50%;
          background-color: #212529; }

.sub-nav-bar {
  display: flex;
  position: relative;
  height: 60px;
  width: 100%;
  padding-top: 5px;
  z-index: 10;
  transition: top 0.2s ease-in-out;
  align-items: flex-start;
  justify-content: center; }
  .sub-nav-bar .nav-link {
    margin-top: 5px; }
    .sub-nav-bar .nav-link a {
      text-decoration: none;
      color: #979797;
      font-size: 14px; }
    .sub-nav-bar .nav-link .active,
    .sub-nav-bar .nav-link .selected {
      color: #212529;
      font-weight: bold; }
      .sub-nav-bar .nav-link .active .nav-tab,
      .sub-nav-bar .nav-link .selected .nav-tab {
        display: flex;
        flex-direction: column;
        align-items: center; }
        .sub-nav-bar .nav-link .active .nav-tab .bottom-dot,
        .sub-nav-bar .nav-link .selected .nav-tab .bottom-dot {
          width: 6px;
          height: 6px;
          margin: auto 5px;
          border-radius: 50%;
          background-color: #212529; }
