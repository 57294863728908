/* bootstrap override */
/* breakpoints */
.tab {
  height: 50px;
  line-height: 50px;
  text-align: center; }
  .tab * {
    color: #212529;
    font-size: 0.875rem;
    font-weight: 600; }
