.incentive-struct-label {
  display: inline-block;
  width: 100%; }

.incentive-struc-form {
  padding: 5px; }
  .incentive-struc-form li {
    display: flex;
    margin-bottom: 10px;
    align-items: center; }

.incentive-struc-form-label {
  display: inline-block;
  width: 110px;
  font-size: 0.8125rem; }

.incentive-struc-form-preview {
  margin-left: auto; }

.form-incentive-input {
  width: 110px;
  text-align: right; }
  .form-incentive-input input {
    text-align: right; }
