@charset "UTF-8";
.form-label {
  width: 100px;
  padding: 10px 0;
  line-height: 1.5;
  font-size: 0.8125rem;
  color: #2d2a2a;
  word-break: keep-all; }

.form-control {
  width: 100%;
  border-radius: 8px;
  height: 38px;
  line-height: 38px;
  font-size: 0.875rem;
  padding: 0, 0.75rem; }

.form-control.form-input {
  border-radius: 5px; }

.form-button {
  position: absolute;
  padding: 0;
  width: 50px;
  height: 25px;
  line-height: 25px;
  border-radius: 5px;
  top: 8px;
  right: 5px;
  font-size: 0.75rem; }

.form-button-address {
  position: absolute;
  padding: 0;
  width: 50px;
  height: 25px;
  line-height: 25px;
  border-radius: 5px;
  top: 26px;
  right: 20px;
  font-size: 0.75rem; }

.invalid-feedback {
  width: 100%;
  border-radius: 8px;
  float: left;
  text-align: left; }

.invalid-feedback.invalid-feedback-with-label {
  width: calc(100% - 100px);
  float: right; }

/* 로그인 버튼 */
.render-field-login-btn {
  padding: 0;
  width: 100px;
  height: 25px;
  line-height: 25px;
  font-size: 0.75rem;
  text-align: center;
  border-radius: 5px; }

/* validation 체크 */
.custom-valid-feedback {
  width: calc(100% - 100px);
  float: right;
  margin-top: 0.25rem;
  font-size: 80%; }

/* 로딩바 */
.async-validating {
  position: absolute;
  z-index: 200;
  height: 15px;
  width: 15px;
  top: 13px;
  right: 10px;
  filter: invert(100%); }
