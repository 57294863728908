/* bootstrap override */
/* breakpoints */
.tabs {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  border-bottom: 0.3px solid #d1d1d1;
  /*
  li:nth-child(1) {
    &.active {
      border-bottom: 2px solid $warning-color;
    }
  }
  li:nth-child(2) {
    &.active {
      border-bottom: 2px solid $brand-color;
    }
  }
  */ }
  .tabs a {
    display: block;
    width: 100%;
    height: 100%; }

.provider-product-tab {
  width: 100%;
  margin: 0 auto;
  max-width: 420px; }
  .provider-product-tab li {
    width: 50%; }
  .provider-product-tab li:first-child.active {
    border-bottom: 2px solid #ffdd45; }
  .provider-product-tab li:nth-child(2).active {
    border-bottom: 2px solid #64bb46; }
