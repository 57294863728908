@charset "UTF-8";
.dashboard-card {
  display: inline-block;
  text-align: right;
  background-color: #ffffff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  margin-right: 10px;
  min-width: 130px;
  min-height: 100px; }

.dashboard-card .card-title {
  padding: 10px;
  font-size: 17px;
  font-weight: 700; }

.dashboard-card .count {
  font-size: 40px;
  padding: 0px 10px 10px 10px; }

.dashboard-groupbox {
  height: auto;
  margin: 2em 0 1em 0;
  border: 1px solid #999;
  padding: 0 8px; }

.dashboard-groupbox h4 {
  line-height: 100%;
  /* 위쪽 테두리 선과 내용물 사이의 간격 */
  padding-left: 8px;
  /* 왼쪽 테두리 선과 라벨 사이의 간격 */
  font-size: 20px;
  /* h4 태그의 텍스트 크기 지정 */
  font-weight: normal;
  /* h4 태그의 텍스트 두께 지정 */ }

.dashboard-groupbox h4 span {
  background-color: #fff;
  /* 배경색과 동일해야 함 */
  color: #333;
  /* 라벨 텍스트 색 */
  padding: 0 4px;
  /* 라벨과 좌우 선 사이의 간격 */
  position: relative;
  top: -8px;
  /* 라벨의 상하 위치 조절 */ }

.dashboard-groupbox div {
  margin-bottom: 1em;
  line-height: 170%; }

.yellow {
  background-color: #ffdd45; }

.green {
  background-color: #64bb46; }

.orange {
  background-color: #f68b1f; }
