.register-product-price {
  width: calc(100% - 100px); }
  .register-product-price li {
    display: flex; }
  .register-product-price .form-group {
    width: 50%; }

.product-regist-price-button.add {
  margin: 0 auto 16px; }

.product-regist-price-button.remove {
  width: 36px;
  height: 36px;
  border-radius: 100%;
  text-indent: -1000rem;
  background-color: #dc3545;
  background-image: url("/images/minus-btn.svg");
  border-color: #dc3545;
  float: right; }

.product-regist-price-button.toggle {
  width: 36px;
  height: 36px;
  margin-left: auto;
  background-image: url("/images/arrow-drop-down-bottom.svg");
  background-repeat: no-repeat;
  background-size: cover; }
  .product-regist-price-button.toggle[value='true'] {
    background-image: url("/images/arrow-drop-down-top.svg"); }
