.table-bordered input[type=radio] {
  -webkit-appearance: radio; }

.gift-list-btn .react-bs-table-csv-btn {
  border: 1px solid #ced4da !important;
  background-color: #588f56;
  color: #ffffff; }

.work-break {
  word-break: break-all; }
