.picture-input {
  position: relative;
  width: 100px;
  height: 100px; }
  .picture-input input {
    display: none; }

.picture-input-cover {
  width: 100%;
  height: 100%;
  overflow: hidden; }

.picture-input-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: 50%;
  background-origin: border-box;
  cursor: pointer; }

.picture-input-edit-btn {
  position: absolute;
  width: 32px;
  height: 32px;
  bottom: -8px;
  right: -8px; }

.picture-input-modal {
  line-height: 1.5; }
