.modal {
  -webkit-overflow-scrolling: touch; }

.os-modal {
  position: relative; }
  .os-modal .modal-content {
    border: 1px solid #64bb46;
    border-radius: 10px; }
  .os-modal .modal-body {
    overflow-y: hidden; }
  .os-modal .modal-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 17px;
    height: 17px;
    background-image: url("/images/modal-close-btn.svg");
    text-indent: -1000rem;
    z-index: 1; }
