.header {
  width: 100%;
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid #007bff;
  background-color: #007bff;
  padding: 0 16px;
  font-weight: 700;
  color: #ffffff;
  z-index: 2;
  position: fixed; }
  .header span {
    height: 100%; }
