.discover-status-form {
  border-bottom: 1px solid #d1d1d1;
  max-width: 420px;
  width: 100%; }
  .discover-status-form .form-label {
    font-weight: 600; }
  .discover-status-form .form-text {
    border: 0;
    padding: 0;
    line-height: 30px; }
  .discover-status-form .form-select {
    width: 150px; }
