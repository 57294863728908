/* bootstrap override */
/* breakpoints */
.tracking-detail {
  width: 100%;
  margin-top: 24px; }

.tracking-detail-status {
  display: flex;
  min-height: 30px;
  margin-bottom: 0;
  align-items: center;
  border-bottom: 1px solid #d1d1d1;
  flex-wrap: nowrap; }
  .tracking-detail-status.header {
    border-top: 1px solid #d1d1d1;
    border-bottom: 2px solid #d1d1d1;
    font-weight: 600;
    position: relative !important; }
  .tracking-detail-status.last-detail {
    background-color: rgba(100, 187, 70, 0.3); }
  .tracking-detail-status div {
    height: 100%;
    padding: 8px;
    line-height: 1.3;
    min-height: 100%;
    text-align: center; }
    .tracking-detail-status div.time {
      width: 30%;
      flex-basis: 30%; }
    .tracking-detail-status div.where {
      width: 35%;
      flex-basis: 35%; }
    .tracking-detail-status div.kind {
      width: 40%;
      flex-basis: 40%; }

.tracking-text {
  text-align: center; }

.order-status-label {
  width: 100px; }
