.table-bordered input[type='radio'] {
  -webkit-appearance: radio; }

tbody .confirmed-status {
  color: #6ad0e2; }

tbody .requested-status {
  color: #6a94e2; }

tbody .delivered-status {
  color: #130fe0; }

tbody .clearing-status {
  color: orange; }

tbody .resolving-status {
  color: purple; }

tbody .cleared-status {
  color: green; }

tbody .canceled-status {
  color: red; }
