.picture-view {
  width: 100px;
  height: 100px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  border: 1px solid #d1d1d1; }

.picture-view-btn {
  position: absolute;
  top: 0;
  right: 0;
  text-indent: -1000rem;
  width: 24px;
  height: 24px;
  background-image: url("/images/minus-btn.svg");
  background-size: 24px 24px; }
