.register-product-form {
  max-width: 420px;
  width: 100%;
  padding: 16px; }
  .register-product-form .form-button {
    font-size: 0.75rem; }
  .register-product-form .form-radio {
    border: 0;
    padding: 0;
    width: 80px;
    line-height: 38px;
    position: relative;
    height: 30px;
    line-height: 30px;
    border: 0;
    padding: 0; }
    .register-product-form .form-radio label {
      padding-left: 26px;
      position: relative;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none; }
      .register-product-form .form-radio label:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 50%;
        width: 21px;
        height: 20px;
        box-sizing: border-box;
        transform: translateY(-50%); }
    .register-product-form .form-radio input[type='radio'],
    .register-product-form .form-radio input[type='checkbox'] {
      width: 0;
      height: 0;
      top: 0;
      left: 0;
      margin-top: 0;
      position: absolute; }
    .register-product-form .form-radio input[type='checkbox'] + label:before {
      border: 1px solid #64bb46; }
    .register-product-form .form-radio input[type='radio'] + label:before {
      background-image: url("/images/radio-unchecked.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 20px; }
    .register-product-form .form-radio input[type='checkbox']:checked + label:before {
      background-image: url("/images/checkbox-checked.svg");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 13px 14px; }
    .register-product-form .form-radio input[type='radio']:checked + label:before {
      background-image: url("/images/radio-checked.svg");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 23px 23px; }
  .register-product-form .form-textarea,
  .register-product-form .form-incentive {
    height: auto; }
  .register-product-form .sample#editor .fr-element {
    font-family: 'Nanum Sqaure'; }

.product-search-btn {
  width: 60px; }

.form-incentive-provider input[type='text'] {
  border: 0;
  background: none;
  cursor: default; }
  .form-incentive-provider input[type='text']:focus {
    border: 0;
    box-shadow: none; }

.product-regist-type-form {
  background-color: #d1d1d1; }

.product-regist-buttons {
  text-align: center; }
  .product-regist-buttons .button {
    width: 120px;
    margin-left: 5px;
    margin-right: 5px; }
