.search-list-header {
  padding: 10px 0;
  border-top: 1px solid #d1d1d1;
  border-bottom: 1px solid #d1d1d1; }
  .search-list-header li {
    display: inline-block;
    width: 33.33%;
    color: #64bb46;
    text-align: center;
    font-weight: 600; }

.search-list-item {
  padding: 10px 0;
  border-bottom: 1px solid #d1d1d1; }
  .search-list-item:last-of-type {
    border-bottom: 0; }
  .search-list-item button {
    width: 100%;
    height: 100%; }

.search-list-item-text {
  display: inline-block;
  width: 33.33%;
  color: #656565;
  text-align: center;
  line-height: 1.3;
  word-break: break-all;
  vertical-align: middle; }

.search-list-item-status {
  display: inline-block;
  width: 65px;
  height: 25px;
  margin: 0 auto;
  line-height: 25px;
  color: #fff;
  border-radius: 5px; }
  .search-list-item-status.wait, .search-list-item-status.audit {
    background-color: #ffdd45;
    color: #979797; }
  .search-list-item-status.success {
    background-color: #64bb46; }
  .search-list-item-status.fail {
    background-color: #dc3545; }

.search-list-text {
  padding: 16px 0 16px;
  text-align: center; }

.search-list-new {
  padding: 16px 0 0;
  border-top: 1px solid #d1d1d1;
  text-align: center; }
  .search-list-new button {
    margin-top: 16px; }
