/* bootstrap override */
/* breakpoints */
.modify-product-tab {
  /*
    position: fixed;
    top: 50px;
    height: 50px;
    z-index: 10;
    background-color: white;
    margin: 0 auto;
    max-width: 950px;
    */
  /*
    .dot-icon {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      display: inline-block;
    }
    */
  /*
    li:first-child {
      &.active {
        border-bottom: 2px solid $brand-color;
      }
    }
  
    li:nth-child(2) {
      &.active {
        border-bottom: 2px solid $brand-color;
      }
    }
  
    li:nth-child(3) {
      &.active {
        border-bottom: 2px solid $warning-color;
      }
    }
    */ }
  .modify-product-tab li.active {
    border-bottom: 2px solid #007bff; }
